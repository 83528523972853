<template>
  <div class="contact-form grid grid-cols-1 lg:grid-cols-2 lg:gap-32">
    <div
      class="py-16 flex flex-col justify-between px-8"
      :class="orientation === 'left' && 'order-2'"
    >
      <header :class="orientation === 'left' && 'text-center'">
        <BaseTitle
          level="h2"
          variant="white"
          class="mb-6"
        >
          {{ data?.form_title }}
        </BaseTitle>
        <p v-if="data?.form_subtitle" class="text-brand-white">
          {{ data?.form_subtitle }}
        </p>
      </header>

      <ContactFormRaw
        :modal-image-src="data?.success_modal_image?.original?.src"
        :cta-text="data.cta_text"
        :terms-and-cons-url="data?.terms_and_cons_link"
        :terms-and-cons-text="data?.terms_and_cons_text"
      />

      <a
        v-if="orientation === 'right'"
        :href="`tel:${companyPhoneNumber}`"
        class="lg:mt-0 mt-4 text-brand-white underline"
      >
        {{ computedPhoneCopy }}
      </a>

      <div v-else class="text-2xl lg:mt-0 mt-4 text-center text-brand-white">
        {{ computedPhoneCopy }}
        <a
          :href="`tel:${companyPhoneNumber}`"
          class="ml-1 text-brand-primary"
        >
          {{ companyPhoneNumber }}
        </a>
      </div>
    </div>

    <div class="relative hidden lg:block py-12 px-8" :class="orientation === 'left' && 'order-1'">
      <NuxtImg
        :src="data?.form_image?.original?.src"
        :alt="data?.form_image?.original?.alt"
        class="w-full max-h-[45rem] object-cover"
        loading="lazy"
        preload
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import BaseTitle from '~/components/BaseTitle.vue';
import ContactFormRaw from '~/components/forms/ContactForm.vue';

interface Props {
  data: any
  orientation?: 'left' | 'right'
}

const props = withDefaults(defineProps<Props>(), {
  orientation: 'right',
});

const webSettings = inject('settings') as Record<string, any>;
const companyPhoneNumber = webSettings?.settings?.company_phone_number || '';
const computedPhoneCopy = computed(() => {
  const phoneText = props.data?.phone_text || '';
  const textToReplace = props.orientation === 'right' ? companyPhoneNumber : '';

  return phoneText.replace('{{ company_phone_number }}', textToReplace);
});
</script>
